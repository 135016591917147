import { FC, useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Fade, Typography, useTheme } from '@mui/material';
import useWindowState from '../../../../hooks/useWindowState';
import { getCompanyConfig, getCompanyConfigFlag } from '../../../../companyConfig/companyConfig';
import { useTranslation } from 'react-i18next';
import { KioskContext } from '../../../../context/KioskContext';

interface Props {
}

type AnimationType = 'Scale' | 'Rotate' | 'Fade' | 'Width';
interface IConceptImage {
  src: string;
  x: number;
  y: number;
}

const AdvertisementPage: FC<Props> = () => {
  const theme = useTheme();
  const {state} = useContext(KioskContext);

  const resolveConceptImages = () : string[] => {
    const images: string[] = [];
    state.salePathData.rootItems.forEach(s => {
      if (s.nodeType.toLowerCase() === 'root' &&  s.imagePath != null && s.imagePath.length>0)
        images.push(s.imagePath);
    });
    return images;
  }

  type MediaType = 'image' | 'video' | 'none';

  const resolveMediaExtension = () : string => {
    const type:string = getCompanyConfig('UI.Advertisement.IdleMediaType','mp4').toLowerCase();
    if (type === 'image') return 'png';
    if (type === 'video') return 'mp4';
    return type;
  }

  const resolveMediaType = () : MediaType => {
    switch(resolveMediaExtension()){
      case 'png': return 'image';
      case 'jpg': return 'image';
      case 'gif': return 'image';
      case 'bmp': return 'image';
      case 'tif': return 'image';
      case 'mp4': return 'video';
      case 'mov': return 'video';
      case 'avi': return 'video';
    }
    return 'none';
  }

  const [idleMedia] = useState<string>(getCompanyConfig('UI.Advertisement.IdleMediaOverride', `${process.env.REACT_APP_BASKET_IMAGE_URI}/BasketKiosk/IdleMedia.${resolveMediaExtension()}`));
  const [idleMediaType] = useState<string>(resolveMediaType());

  const [showButton] = useState<Boolean>(getCompanyConfigFlag('UI.Advertisement.ShowButton', false));
  const [buttonFontSize] = useState<number>(Number.parseInt(getCompanyConfig('UI.Advertisement.ButtonFontSize', '0')));
  const [buttonLanguageInterval] = useState<number>(Number.parseInt(getCompanyConfig('UI.Advertisement.ButtonLanguageInterval', '2')));
  const [buttonAnimationTime] = useState<number>(Number.parseInt(getCompanyConfig('UI.Advertisement.ButtonAnimationInterval', '1')));
  const [languageCounter, setLanguageCounter] = useState<number>(buttonLanguageInterval);

  const [showConceptImages] = useState<Boolean>(getCompanyConfigFlag('UI.Advertisement.ShowConceptImages', true));
  const [conceptImageInterval] = useState<number>(Number.parseInt(getCompanyConfig('UI.Advertisement.ConceptImageInterval', '4')));
  const [conceptImages] = useState<string[]>(resolveConceptImages());
  const [conceptImageIndex, setConceptImageIndex] = useState<number>(-1);
  const [conceptImage, setConceptImage] = useState<IConceptImage>({src:'',x:0,y:0});
  const [conceptImageCounter, setConceptImageCounter] = useState<number>(0);

  const { width: windowWidth, height: windowHeight } = useWindowState();

  const [language, setLanguage] = useState<string>('fi');
  const {i18n} = useTranslation();

  const video = document.querySelector("video");

  let animationType: AnimationType = 'Scale';

  const randomInt = (min: number=0, max: number=1) : number => {
    const rand = min + Math.random() * (max - min);    
    return Math.round(rand);
  }

  useEffect(() => {
    const timerEventLanguage = () => {
      if(languageCounter>0)setLanguageCounter((c)=>c-1);
      if (buttonLanguageInterval>0&&languageCounter===0){
        setLanguageCounter(buttonLanguageInterval);
        switch(language){
          case 'en':{
            setLanguage('fi');
            break;
          }
          case 'se':{
            setLanguage('en');
            break;
          }
          default:{
            setLanguage('se');
            break;
          }
        }
      }
    }

    const timerEventConceptImage = () => {
      if(conceptImageCounter>0)setConceptImageCounter((c)=>c-1);
      if (conceptImageInterval>0&&conceptImageCounter===1){
        conceptImageVisible(false);
      }
      if (conceptImageInterval>0&&conceptImageCounter===0){
        setConceptImageCounter(conceptImageInterval);

        let index: number = conceptImageIndex + 1;
        if (index >= conceptImages.length)index = 0;
        setConceptImageIndex(index);
  
        const image: string = conceptImages[index];
        const width: number = (windowWidth||1080);
        const height: number = (windowHeight||1920);
        const imgWidth = width * 0.4;
        const imgHeight = width * 0.3;
        const margin: number = width * 0.2; 
        const x = randomInt(width-imgWidth-(2*margin))+margin;
        const y = randomInt(height-imgHeight-(2*margin))+margin;
        setConceptImage({src:image, x:x, y:y});
        //reset_ConceptImageAnimation();
        conceptImageVisible(true);
      }
    }

    const to:NodeJS.Timeout = setTimeout(()=>{
      if (video?.paused)video?.play();
      timerEventLanguage();
      timerEventConceptImage();
      },1000);
    return () => {
      clearTimeout(to);
    };
  },[languageCounter, language, video, buttonLanguageInterval, conceptImageCounter, conceptImageInterval, conceptImageIndex, conceptImages, windowWidth, windowHeight]);

  const getText = () : string => {
    return i18n.getFixedT(language)('button.start');
  }  
  
  const buttonText: string = getText();
  const buttonTextMaxLength: number = 40;
  const buttonTextMplier: number = 1 - ((buttonText.length/buttonTextMaxLength) > 1 ? 1 : buttonText.length/buttonTextMaxLength);
  const buttonFontBase: number = (windowWidth||1080) * 0.07;
  const buttonFont: number = buttonFontSize > 0 ? buttonFontSize : (buttonFontBase + (buttonFontBase*buttonTextMplier));

  const getKeyframes = (name: string, type: AnimationType) : string => {
    switch(type){
      case 'Scale': {
        return `@keyframes ${name} {
            100% {transform: scale(1.2);}
          }`;
      }
      case 'Fade': {
        return `@keyframes ${name} {
            0% {opacity: 0;}
            100% {opacity: 0;}
          }`;
      }
      case 'Rotate': {
        return `@keyframes ${name} {
            100% {transform: rotate(360deg);}
          }`;
      }
      case 'Width': {
        return `@keyframes ${name} {
            100% {transform: width;}
          }`;
      }
    }
  }

  function conceptImageVisible(visible: boolean) {
    let element = document.getElementById('conceptImage');
    if (element)
      element.style.opacity = visible ? '1' : '0';
  }

  return (
    <div id='page' className={styles.advertisementPage}>
      <div className={styles.kioskFront}>
        {idleMediaType === 'image' && (
          <Fade in={true}>
            <img src={idleMedia} alt=''/>
          </Fade>
        )}
        {idleMediaType === 'video' && (
          <Fade in={true}>
            <video width={windowWidth||1080} height={windowHeight||1920} autoPlay loop muted>
              <source src={idleMedia} type="video/mp4"/>
            </video>
          </Fade>
        )}
      </div>
      {(showButton && buttonText !== '') && (
      <div className={styles.button} style={{backgroundColor: theme.palette.primary.main, animation: `buttonKeyFrames ${buttonAnimationTime}s alternate infinite ease-in`}}>
        <Typography fontSize={buttonFont} fontWeight={700} color={theme.palette.background.default}>{buttonText}</Typography>
        <style>{getKeyframes('buttonKeyFrames', animationType)}</style>
      </div>
      )}
      {(showConceptImages && conceptImage.src !== '') && (
        <div id='conceptImage' className={styles.conceptImage} style={{left: conceptImage.x, top:conceptImage.y, opacity: 0, transition: 'opacity 1s'}}>
          <img src={conceptImage.src} alt=''/>
        </div>
      )}
    </div>
  )
};


export default AdvertisementPage;
